<template>
	<div class="regional-market">
		<div class="title-card">
			<div class="title-box">
				<div class="title">区域市场研究报告</div>
				<div class="result">
					<span>共找到</span>
					<span class="num">{{ total }}</span>
					<span>条结果</span>
				</div>
			</div>
			<div class="condition-box">
				<div class="condition-item">
					<div class="title">省份</div>
					<div class="item-box">
						<div class="all-btn" :class="isProAll ? 'all-btn-select' : ''" @click="proAll">全部</div>
						<div class="name-box" :class="provincesStatus ? 'name-open' : ''">
							<div class="name" :class="proSelect.indexOf(item.code) !== -1 ? 'name-select' : ''" v-for="item in provinceData" :key="item.code" @click="selectPro(item)">
								{{ item.name }}
							</div>
						</div>
					</div>
					<div class="open" @click="foldProvinces">
						<span>{{ provincesStatus ? "收起" : "展开" }}</span>
						<span class="iconfont icon-sanjiaoxing" :class="provincesStatus ? 'iconfont-open' : ''"></span>
					</div>
				</div>
				<div class="condition-item" v-show="cityData.length != 0">
					<div class="title">地级市</div>
					<div class="item-box">
						<div class="name-box" :class="cityStatus ? 'name-open' : ''">
							<div class="name" :class="citySelect.indexOf(item.code) !== -1 ? 'name-select' : ''" v-for="item in cityData" :key="item.code" @click="selectCity(item)">
								{{ item.name }}
							</div>
						</div>
					</div>
					<div class="open" @click="foldCity">
						<span>{{ cityStatus ? "收起" : "展开" }}</span>
						<span class="iconfont icon-sanjiaoxing" :class="cityStatus ? 'iconfont-open' : ''"></span>
					</div>
				</div>
				<div class="condition-item">
					<div class="title" style="margin-top: 10px">年份</div>
					<div class="year-box">
						<div class="all-btn" :class="isYearAll ? 'all-btn-select' : ''" @click="yearAll">不限</div>
						<div class="name-box">
							<div class="name" :class="yearSelect.indexOf(item.code) !== -1 ? 'name-select' : ''" v-for="item in yearData" :key="item.code" @click="selectYear(item)">
								{{ item.name }}
							</div>
						</div>
						<div class="custom-box">
							<div class="custom">自定义</div>
							<div>
								<el-date-picker v-model="startYear" type="year" :picker-options="startTime" placeholder="选择开始年份" value-format="yyyy" @change="changeYear"></el-date-picker>
							</div>
							<div class="to">至</div>
							<div>
								<el-date-picker v-model="endYear" type="year" :picker-options="endTime" placeholder="选择结束年份" value-format="yyyy" @change="changeYear"></el-date-picker>
							</div>
						</div>
					</div>
				</div>
				<div class="condition-item" v-show="selectedProData.length != 0 || selectedCityData.length != 0 || selectedYearData.length != 0">
					<div class="title">已选条件</div>
					<div class="selected-box">
						<div class="name" v-for="(item, i) in selectedProData" :key="item.code">
							<div>{{ item.name }}</div>
							<div class="clear-icon" @click="delSelectData(item, i, 1)"></div>
						</div>
						<div class="name" v-for="(item, i) in selectedCityData" :key="item.code">
							<div>{{ item.name }}</div>
							<div class="clear-icon" @click="delSelectData(item, i, 2)"></div>
						</div>
						<div class="name" v-for="(item, i) in selectedYearData" :key="item.code">
							<div>{{ item.name }}</div>
							<div class="clear-icon" @click="delSelectData(item, i, 3)"></div>
						</div>
					</div>
					<!-- <div class="empty-btn" @click="emptySelect">清空筛选条件</div> -->
				</div>
				<div class="search-button">
					<el-button type="primary" @click="handleQuery">搜索</el-button>
					<el-button @click="emptySelect">清空</el-button>
				</div>
			</div>
		</div>
		<div class="result-box" v-show="total == 0" style="margin-top: 100px">
			<empty :name="'暂无数据'"></empty>
		</div>
		<div class="result-box" v-show="total != 0">
			<el-row :gutter="20">
				<el-col :span="12" v-for="(item, i) in pageReport" :key="i">
					<div class="grid-content" @click="toDetail(item.reportId)">
						<img @dragstart.prevent :src="item.frontCoverImg" alt="" />
						<div class="right-box">
							<el-tooltip effect="light" :content="item.reportTitle" placement="bottom-start">
								<div class="title text-ellipsis-two">
									{{ item.reportTitle }}
								</div>
							</el-tooltip>
							<div class="content text-ellipsis-four">
								{{ item.reportDesc }}
							</div>
							<div class="price-box">
								<div v-if="item.readPrice != 0">
									订阅价格：
									<span class="unit">￥</span>
									<span class="price">{{ item.readPrice }}</span>
									/期
								</div>
								<div v-if="item.readPrice == 0">
									订阅价格：
									<span class="unit"></span>
									<span style="color: #23ab82">免费</span>
								</div>
								<div class="down-price" v-if="item.downloadPrice != 0">
									下载价格：
									<span class="unit">￥</span>
									<span class="price">{{ item.downloadPrice }}</span>
									/期
								</div>
								<div class="down-price" v-if="item.downloadPrice == 0">
									下载价格：
									<span class="unit"></span>
									<span style="color: #23ab82">免费</span>
								</div>
							</div>
							<div class="member">
								会员价格：
								<span>免费</span>
							</div>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<div class="pagination-box">
			<pagination ref="pagination" :total="total" @change="paginChange" v-show="total != 0"></pagination>
		</div>
		<login-modal ref="loginModal"></login-modal>
	</div>
</template>

<script>
import Pagination from "@/components/Pagination";
import LoginModal from "@/components/loginModal";
import Empty from "@/components/Empty";
export default {
	components: {
		Pagination,
		LoginModal,
		Empty,
	},
	data() {
		return {
			provinceData: [], // 省份数据
			cityData: [], // 市级数据
			provincesStatus: false, // 省份展开收起状态
			cityStatus: false, // 地级市展开收起状态
			proSelect: [], // 选中的省份
			isProAll: true, // 省份是否全选
			citySelect: [], // 选中的地级市
			isYearAll: true, // 年份是否全选
			yearSelect: [], // 选中的年份
			yearData: [],
			selectedProData: [], // 已选的省级数据
			selectedCityData: [], // 已选地级市数据
			selectedYearData: [], // 已选地年份数据
			startYear: "",
			endYear: "",
			pageReport: [],
			total: 0,
			conPO: {
				platform: 1,
				childModuleCode: "10011001",
				pageSize: 10, //页数
				pageNum: 1, // 页码
			},
			startTime: {
				disabledDate: time => {
					if (this.endYear) {
						return time.getTime() > new Date(this.endYear).getTime();
					}
				},
			}, // 控制开始年份
			endTime: {
				disabledDate: time => {
					if (this.startYear) {
						return time.getTime() < new Date(this.startYear).getTime();
					}
				},
			}, // 控制结束年份
		};
	},
	mounted() {
		// 数据埋点
		this.$api.home.buriedPoint({
			terminal: "60441001",
			consumerId: this.$session.getUsers() ? this.$session.getUsers().consumerId : "",
			positionCode: "604410011002",
		});
		this.getReportPage();
		this.getReportSearch();
	},
	methods: {
		// 研报列表-查询条件获取（省-年份）
		getReportSearch() {
			this.$api.research
				.getReportSearch({
					platform: 1,
					childModuleCode: "10011001",
				})
				.then(res => {
					let data = res.data;
					this.provinceData = data.reportProvince;
					data.reportYear.forEach(item => {
						item.name = item.reportYear;
						item.code = item.reportYear;
					});
					this.yearData = data.reportYear.sort((x, y) => y["reportYear"] - x["reportYear"]);
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 研报列表-查询条件获取（市）
		getReportSearchCity(item) {
			let {proSelect, cityData, selectedProData} = this;
			this.$api.research
				.getReportSearchCity({
					platform: 1,
					childModuleCode: "10011001",
					provinceCode: item.code,
				})
				.then(res => {
					let data = res.data;
					proSelect.push(item.code);
					this.proSelect = proSelect;
					this.cityData = [...cityData, ...data];
					this.selectedProData = [...selectedProData, ...[item]];
					this.isProAll = false;
					// this.getReportPage();
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 点击搜索
		handleQuery() {
			this.$refs.pagination.page = 1;
			this.conPO.pageNum = 1;
			this.conPO.pageSize = 10;
			this.getReportPage();
		},
		// 获取研报列表
		getReportPage() {
			let {conPO, proSelect, citySelect, yearSelect, startYear, endYear} = this;
			conPO.province = proSelect.toString();
			conPO.city = citySelect.toString();
			conPO.year = yearSelect.toString();
			conPO.startYear = startYear;
			conPO.endYear = endYear;
			this.$api.research
				.getReportPage(conPO)
				.then(res => {
					let rows = res.rows;
					this.pageReport = rows;
					this.total = res.total;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 展开收起省份
		foldProvinces() {
			this.provincesStatus = !this.provincesStatus;
		},
		// 展开收起地级市
		foldCity() {
			this.cityStatus = !this.cityStatus;
		},
		// 单选省份
		selectPro(item) {
			let {proSelect, cityData, selectedProData, citySelect, selectedCityData} = this;
			let valueIndex = proSelect.indexOf(item.code);
			if (valueIndex == -1) {
				// 判断市级数据中是否已经存在当前省的市级数据
				if (cityData.some(key => key.code.slice(0, 2) == item.code.slice(0, 2))) {
					// 处理市级数据（不需要当前省级的市级数据）
					let newCitySelect = [];
					citySelect.forEach(key => {
						if (item.code.slice(0, 2) != key.slice(0, 2)) {
							newCitySelect.push(key);
						}
					});
					this.citySelect = newCitySelect;
					// 处理已选条件的市级数据（不需要当前省级的市级数据）
					let newSelectedCityData = [];
					selectedCityData.forEach(key => {
						if (item.code.slice(0, 2) != key.code.slice(0, 2)) {
							newSelectedCityData.push(key);
						}
					});
					this.selectedCityData = newSelectedCityData;
					// 处理省级数据选中状态
					proSelect.push(item.code);
					this.selectedProData = [...selectedProData, ...[item]];
					if (this.proSelect.length == 0 && this.citySelect.length == 0) {
						this.isProAll = true;
					} else {
						this.isProAll = false;
					}
					// this.getReportPage();
					return;
				}
				// 选择
				this.getReportSearchCity(item);
			} else {
				// 取消选择
				proSelect.splice(valueIndex, 1);
				// 删除对应的市级数据
				let cityResult = [];
				cityData.forEach(key => {
					if (item.code.slice(0, 2) != key.code.slice(0, 2)) {
						cityResult.push(key);
					}
				});
				this.cityData = cityResult;
				// （删除已选的省级数据）比较 item和selectedProData取出不同的值
				let itemArr = [item];
				let selectedCodes = [];
				itemArr.forEach(({code}) => {
					selectedCodes.push(code);
				});
				let selectedResult = selectedProData.filter(({code}) => !selectedCodes.includes(code));
				this.selectedProData = selectedResult;
				// this.getReportPage();
			}
			if (this.proSelect.length == 0 && this.citySelect.length == 0) {
				this.isProAll = true;
			} else {
				this.isProAll = false;
			}
		},
		// 全选省份
		proAll() {
			if (this.proSelect.length == 0 && this.citySelect.length == 0) {
				return;
			}
			this.isProAll = !this.isProAll;
			if (this.isProAll) {
				this.selectedProData = [];
				this.proSelect = [];
				this.cityData = [];
				this.selectedCityData = [];
				this.citySelect = [];
			}
			// this.getReportPage();
		},
		// 单选地级市
		selectCity(item) {
			let {citySelect, selectedCityData, proSelect, selectedProData} = this;
			let valueIndex = citySelect.indexOf(item.code);
			if (valueIndex == -1) {
				// 选择
				citySelect.push(item.code);
				this.selectedCityData = [...selectedCityData, ...[item]];
				// 处理省级数据（不需要当前市级的省级数据）
				let newCitySelect = [];
				proSelect.forEach(key => {
					if (item.code.slice(0, 2) != key.slice(0, 2)) {
						newCitySelect.push(key);
					}
				});
				this.proSelect = newCitySelect;
				// 处理已选条件的省级数据（不需要当前市级的省级数据）
				let newSelectedProData = [];
				selectedProData.forEach(key => {
					if (item.code.slice(0, 2) != key.code.slice(0, 2)) {
						newSelectedProData.push(key);
					}
				});
				this.selectedProData = newSelectedProData;
				if (this.proSelect.length == 0) {
					this.isProAll = false;
				}
				// this.getReportPage();
			} else {
				// 取消选择
				citySelect.splice(valueIndex, 1);
				// 比较 item和selectedCityData取出不同的值
				let itemArr = [item];
				let selectedCodes = [];
				itemArr.forEach(({code}) => {
					selectedCodes.push(code);
				});
				let selectedResult = selectedCityData.filter(({code}) => !selectedCodes.includes(code));

				if (selectedResult.length == 0) {
					// 无选中的市时
					this.isProAll = true;
				}
				this.selectedCityData = selectedResult;
				// this.getReportPage();
			}
		},
		// 全选年份
		yearAll() {
			if (this.yearSelect.length == 0 && this.startYear == "" && this.endYear == "") {
				return;
			}
			this.isYearAll = !this.isYearAll;
			if (this.isYearAll) {
				this.startYear = "";
				this.endYear = "";
				this.selectedYearData = [];
				this.yearSelect = [];
			}
			// this.getReportPage();
		},
		// 单选年份
		selectYear(item) {
			let {yearSelect, selectedYearData} = this;
			let valueIndex = yearSelect.indexOf(item.code);
			if (valueIndex == -1) {
				// 选择
				yearSelect.push(item.code);
				this.selectedYearData = [...selectedYearData, ...[item]];
				this.startYear = "";
				this.endYear = "";
				// this.getReportPage();
			} else {
				// 取消选择
				yearSelect.splice(valueIndex, 1);
				// 比较 item和selectedYearData取出不同的值
				let itemArr = [item];
				let selectedCodes = [];
				itemArr.forEach(({code}) => {
					selectedCodes.push(code);
				});
				let selectedResult = selectedYearData.filter(({code}) => !selectedCodes.includes(code));
				this.selectedYearData = selectedResult;
				// this.getReportPage();
			}
			if (this.yearSelect.length == 0) {
				this.isYearAll = true;
			} else {
				this.isYearAll = false;
			}
		},
		changeYear() {
			this.isYearAll = false;
			this.selectedYearData = [];
			this.yearSelect = [];
			if ((this.startYear == "" || this.startYear == null) && (this.endYear == "" || this.endYear == null)) {
				this.isYearAll = true;
				// this.getReportPage();
			}
		},
		// 删除单条所选内容
		delSelectData(item, index, type) {
			if (type == 1) {
				// 删除省
				let {proSelect, cityData, citySelect} = this;
				this.selectedProData.splice(index, 1);
				// 删除对应的市级数据
				let cityResult = [];
				cityData.forEach(key => {
					if (item.code.slice(0, 2) != key.code.slice(0, 2)) {
						cityResult.push(key);
					}
				});
				this.cityData = cityResult;
				let newData = proSelect.filter(key => ![item.code].includes(key));
				// if (newData.length == 0 && this.selectedCityData.length == 0) {
				//   // 无选中的省，市
				//   this.isProAll = true;
				//   this.selectedProData = [];
				//   this.proSelect = [];
				//   this.cityData = [];
				//   this.selectedCityData = [];
				//   this.citySelect = [];
				// }
				this.proSelect = newData;
				if (proSelect.length == 1 && citySelect.length == 0) {
					this.cityData = [];
				}
				// this.getReportPage();
				if (this.proSelect.length == 0 && this.citySelect.length == 0) {
					this.isProAll = true;
				} else {
					this.isProAll = false;
				}
			} else if (type == 2) {
				// 删除市
				let {citySelect, selectedProData} = this;
				this.selectedCityData.splice(index, 1);
				let newData = citySelect.filter(key => ![item.code].includes(key));
				if (newData.length == 0) {
					this.selectedCityData = [];
					this.citySelect = [];
				}
				if (newData.length == 0 && this.proSelect.length == 0) {
					this.isProAll = true;
				}
				this.citySelect = newData;
				if (citySelect.length == 1 && selectedProData.length == 0) {
					this.cityData = [];
				}
				// this.getReportPage();
			} else if (type == 3) {
				// 删除年份
				let {yearSelect} = this;
				this.selectedYearData.splice(index, 1);
				let newData = yearSelect.filter(key => ![item.code].includes(key));
				if (newData.length == 0) {
					this.isYearAll = true;
					this.selectedYearData = [];
					this.yearSelect = [];
				}
				this.yearSelect = newData;
				// this.getReportPage();
			}
		},
		// 清空筛选条件
		emptySelect() {
			this.cityData = [];
			this.proSelect = [];
			this.isProAll = true;
			this.citySelect = [];
			this.isYearAll = true;
			this.yearSelect = [];
			this.selectedProData = [];
			this.selectedCityData = [];
			this.selectedYearData = [];
			this.startYear = "";
			this.endYear = "";
			this.getReportPage();
		},
		// 点击列表进入详情
		toDetail(reportId) {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				// 判断阅读，下载及会员情况
				this.$api.research
					.getReadAndDownload({
						reportId,
					})
					.then(res => {
						let data = res.data;
						if (data.read == "10011001") {
							// 有阅读权限
							let url = this.$router.resolve({
								path: `/research/market-detail?type=1&id=${reportId}`,
							});
							window.open(url.href, "_blank");
						} else {
							// 没有阅读权限
							let url = this.$router.resolve({
								path: `/research/market-preview?type=1&id=${reportId}`,
							});
							window.open(url.href, "_blank");
						}
					})
					.catch(msg => {
						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					});
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		// 分页方法
		paginChange(page, pageSize) {
			this.conPO.pageNum = page;
			this.conPO.pageSize = pageSize;
			this.getReportPage();
		},
	},
};
</script>
<style lang="less" scope>
@import "./index.less";
</style>
